var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-start":""}}),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","lg11":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-between":"","py-2":""}},_vm._l((_vm.navItems),function(item,i){return _c('v-flex',{key:i,attrs:{"px-2":""}},[_c('v-btn',{style:({
              border:
                _vm.contents == item.name
                  ? '1px solid #ffe200'
                  : !_vm.contents &&
                    _vm.contents == 'Donate to the Cause of Conservation'
                  ? '1px solid #ffe200'
                  : 'none',
            }),attrs:{"block":"","text":"","ripple":false},on:{"click":function($event){return _vm.loadStatic(item)}}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"14px","color":"#343434","text-transform":"none"}},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)}),1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('GetInvolvedContent',{attrs:{"content":_vm.contents,"routeName":_vm.routeNames}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }